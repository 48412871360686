@import '../../styles/utilities';
@import '../../styles/base';

.navbar {
  display: flex;
  flex-direction: row;
  height: px2rem(100);
  align-items: center;
  justify-content: center;
  box-shadow: px2rem(5) px2rem(10) px2rem(10) rgb(0 0 0 / 20%);

  .nav-wrapper {
    width: 90%;

    .brand-logo {
      margin-top: px2rem(8);
      justify-content: center;
      color: black;
    }
  }

  .nav-link {
    @extend .link-black;
    font-size: px2rem(16);
    margin-right: px2rem(32);

    &:hover {
      border-radius: px2rem(10);
    }
  }

  .navbar-brand {
    font-size: px2rem(26);
    font-weight: 600;
    margin-right: px2rem(48);
    text-decoration: none;

    &:hover {
      background-color: transparent;
      color: #6e6e6e !important;
    }
  }
}

#mobile-nav {
  background-color: #e3f2fdec;
}

@media screen and (max-width: px2rem(480)) {
  .navbar {
    justify-content: start;
    height: px2rem(70) !important;
  }

  #mobile-nav {
    padding: px2rem(24) px2rem(10);
  }

  .mobile-drawer-icon {
    color: black;
  }

  .nav-link {
    color: #1f7267 !important;
  }
}

@media only screen and (max-width: 900px) {
  .navbar {
    justify-content: start;
    height: px2rem(80);
  }

  #mobile-nav {
    padding: px2rem(18) px2rem(10);
  }
}
