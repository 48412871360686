@import './base/index';
@import './utilities/index';
@import './pages/index';

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

.em {
  font-weight: bold;
}

.ita {
  font-style: italic;
}
