.timepicker-modal {
  box-shadow: none;
  background: none;
}

.modal {
  background: white;
  border-radius: 8px;
}

.modal-content {
  border: none;
}
